

.backgroundImage {
    position: absolute;
    background-image: url(./assets/background.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
     /* min-height: max(95vh, 700px); */
}

.main{
    display: flex;
    flex-direction: column;
   position: relative;
    font-family: 'VarelaRound';
    font-weight: 400;
    height: 100vh;
    color: var(--secondary-color);
    font-style: normal;
}

h1, h2 {
    font-family: 'Anton';
    font-weight: 300;
}

nav {
    padding-top: max(1.5vw, 10px);
    padding-inline: max(2vw, 10px);
}

nav img {
    width: max(50px, 15%);
}

.mainSection {
    background-color: var(--primary-color);
    text-align: center;
    margin-inline: auto;
    border: 3.5px solid var(--accent-color);
    border-radius: 15px;
    padding: max(1vw, 15px);
    width: max(280px, 28vw);
}

.mainHeading {
    padding-block: max(.7vw, 10px);
    border-bottom: 2px solid var(--accent-color);
}

.stats {
    text-align: left;
    padding-block: max(.6vw, 10px);
    border-bottom: 2px solid var(--accent-color);
}

.subheading {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    padding-inline: max(.3vw, 5px);
}

.nftImage {
    width: max(16vw, 180px);
    margin-inline: auto;
    margin-block: max(1vw, 10px);
    border-radius: 10px;
    background-color: #1c1d23;
}

.nftImage img {
    border-radius: 10px 10px 0 0;
}

.canMint {
    padding-block: 5px;
    margin-top: 10px;
    background-color: white;
    color: black;
    font-family: 'Anton';
    border-radius: 5px;
}

.nftImage input {
    width: 100%;
}

button:disabled {
    filter: grayscale(1);
}

#inputButton {
    display: none;
}

/* .nalikesFooter {
    background-color: var(--primary-color);
    position: absolute;
    bottom: 0;
    width: 100%;
} */

.nalikesFooter h3 {
    font-size: var(--paragraph-font-size);
    padding-block: 5px;
    display: flex;
    justify-content: center;
    place-items: center;
}

.nalikesFooter img {
    max-width: max(4%, 50px);
}

.cryptoButton {
    display: flex;
    justify-content: center;
    align-content: center;
}

.cryptoButton img {
    width: max(10px, 9%);
}

.disconnectButton {
    display: none;
    justify-content: center;
    align-content: center;
    text-decoration: underline;
}

.disconnectButton:hover {
    color: var(--accent-color)
}

.popup {
    min-height: 100vh;
    width: 100vw;
    position: fixed;
    backdrop-filter: blur(5px);
    z-index: 1;
    /* needs to be flex */
    display: none;
    justify-content: center;
    place-items: center;
}

.popupBox {
    width: max(300px, 22.5vw);
    background-color: var(--primary-color);
    text-align: center;
    min-height: 25vh;
    border-radius: 10px;
    border: 3px solid var(--accent-color);
    padding: max(1vw, 5px);
    text-align: -webkit-center;
}

.popup h1 {
    font-weight: 300;
    font-size: clamp(1rem, 1.3vw, 2rem);
}

.popup img {
    max-width: 30%;
    margin: max(1.5vw, 10px);
}

.popup h2 {
    font-size: clamp(1rem, 1.1vw, 2rem);
    font-family: "VarelaRound";
    color: #959595;
    padding-bottom: 10px;
}

.popup a:hover{
    color: var(--accent-color);
}

.popupHeading {
    display: flex;
}

.statusHeading {
    width: 94%;
    padding-left: 6%;
}

.closeX {
    width: 5%;
    text-align: left;
    color: rgb(157, 157, 157);
    font-family: "VarelaRound";
    cursor: pointer;
}




.preloading {
    display: flex;
    /* background-color: var(--primary-color); */
    background-image: url(./assets/background-blurred.png);
}

.preloading img {
    max-width: 30%;
    -webkit-animation: flickerAnimation 2.5s infinite;
    -moz-animation: flickerAnimation 2.5s infinite;
    -o-animation: flickerAnimation 2.5s infinite;
    animation: flickerAnimation 2.5s infinite;
}

.nftpaypopup {
    width: 100%;
    height: 100%;
    position: absolute;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
}

#nftpaypopup {
    display: none;
}
.nftpay_iframe {
    height: max(510px, 65vh);
    border: none;
    border-radius: 10px;
    background-color: white;
    padding: 2vw;
}

.closeNFTPay {
    width: 5%;
    text-align: left;
    color: #8c4bdc;
    font-family: "VarelaRound";
    cursor: pointer;
    position: absolute;
    right: 4%;
    top: 2%;
}

.nftpayinner {
    position: relative;
    
}

* {
    transition: all 0.1s ease-in-out;
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.spinner {
    display: flex;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    margin: max(2vw, 40px);
    background: radial-gradient(farthest-side, var(--accent-color) 94%, #0000) top/9px 9px no-repeat,
        conic-gradient(#0000 30%, var(--accent-color));
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
    animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
    100% {
        transform: rotate(1turn);
    }
}

.nalikesFooter {
    display: flex;
    font-size: .55em;
    justify-content: flex-end;
    align-items: center;
    padding-top: max(1vw,5px);
}